class Index {
  constructor() {
    this.logo = document.querySelector('.logo');
    this.lists = document.querySelector('.top-list-menu');
    this.footer = document.querySelector('footer');
  }

  registerEvents() {
    this.logo.addEventListener('click', () => {
      this.logo.classList.add('fadeout');
    });
    this.logo.addEventListener('animationend', () => {
      this.logo.classList.add('remove');
      this.lists.classList.add('add');
      setTimeout(() => {
        this.lists.classList.add('fadein');
      }, 50);
    });
    this.lists.addEventListener('animationend', () => {
      this.footer.classList.add('open');
    });
  }
}

const index = new Index();

index.registerEvents();
